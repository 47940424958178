import Button from 'components/dist/atoms/Button'
import Input from 'components/dist/atoms/Input'
import Label from 'components/dist/atoms/Label'
import Separator from 'components/dist/atoms/Separator';
import Stack from 'components/dist/atoms/Stack'
import React, { useState } from 'react';

export const DuplicateTabs: React.FC = () => {
    const [tabCount, setTabCount] = useState<number>(1);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value, 10);
        if (value > 10) {
            setTabCount(10);
        } else {
            setTabCount(value);
        }
    };

    const handleConfirm = () => {
        if (tabCount <= 10) {
            // open new tabs
            for (let i = 0; i < tabCount; i++) {
                window.open(window.location.href);
            }
        }
    };

    return (
        <Stack space='md'>
            <Stack space='sm' className='px-4 pt-4'>
                <Label
                    variant='secondary'
                    size='sm'
                    htmlFor='tabCount'
                >Number of Tabs (Max 10)</Label>
                <Input
                    name="tabCount"
                    id="tabCount"
                    type="number"
                    maxLength={10}
                    value={tabCount}
                    onChange={handleInputChange}
                    placeholder="Enter number of tabs"
                />
            </Stack>
            <Separator />
            <Stack row items='center' justify='end' space='sm' className='px-3 pb-2'>
                <Button
                    data-dropdown-close
                    variant='outline'>Cancel</Button>
                <Button onClick={handleConfirm} disabled={tabCount > 10}>Duplicate</Button>
            </Stack>
        </Stack>
    );
};