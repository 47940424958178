import { useRouter } from "next/router";
import React from "react";
import { SearchResultDTO } from "src/backend";
import { QUERY_PARAM_LOAN_ID } from 'src/constants/query-params';
import { Route } from "src/constants/routing";
import { useGetLoanByIdQuery, useSearchLoansQuery } from "src/services/loanApi";
import { useDebounceValue } from "usehooks-ts";

import { SearchAutocompleteProps } from "./search-autocomplete.types";


export const useSearchAutocomplete = (props: SearchAutocompleteProps) => {
    const router = useRouter();
    const [query, setQuery] = React.useState<string>('');
    const loanId = router.query[QUERY_PARAM_LOAN_ID] as string;
    const { currentData: loan } = useGetLoanByIdQuery(loanId, {
        skip: !loanId
    });

    const [debouncedQuery] = useDebounceValue(query, 500);

    const { data = { items: [] }, isFetching }: {
        data?: { items: SearchResultDTO[] },
        isFetching: boolean
    } = useSearchLoansQuery({
        q: debouncedQuery,
        // page
        p: 0,
        d: 'd',
        // context is loan id
        c: loan?.id ?? '',
        ot: props.searchItems ?? [],
        searchType: props.searchLinkType ?? 'NORMAL'
    }, {
        skip: debouncedQuery.length === 0
    });

    const onClearSearchClick = () => {
        setQuery('');
    }

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
    }

    const uiView = getCurrentUiView(router.pathname);

    return {
        placeholder: getByPlaceholderText(uiView, loan?.projectName),
        results: data,
        isFetching,
        onClearSearchClick,
        onInputChange,
        query,
        noResults: !isFetching && data?.items.length === 0 && debouncedQuery.length > 0
    } as const;
};

type UIViewType = 'EXPLORER' | 'KANBAN' | 'LOAN_SHOEBOX' | 'MY_SHOEBOX' | 'TRASH'

const getByPlaceholderText = (view: UIViewType, projectName?: string): string => {
    if (view === 'EXPLORER') {
        return `Search Loan Explorer`
    } else if (view === 'LOAN_SHOEBOX') {
        return `Search Loan Shoebox`
    } else if (view === 'MY_SHOEBOX') {
        return `Search My Shoebox`
    } else if (view === 'KANBAN') {
        return `Search anything`
    } else if (view === 'TRASH') {
        return `Search Trash`
    } else if (projectName) {
        return `Search ${projectName}`
    } else {
        return `Search anything`
    }
}

const getCurrentUiView = (pathname: string): UIViewType => {
    if (pathname === Route.LOAN_EXPLORER_LOANS) {
        return 'EXPLORER'
    } else if (([Route.LOAN_EXPLORER_LOANS_LOAN_ID_SHOEBOX, Route.LOAN_EXPLORER_LOANS_LOAN_ID_SHOEBOX_USER_ID] as string[]).includes(pathname)) {
        return 'LOAN_SHOEBOX'
    } else if (([Route.LOAN_EXPLORER_SHOEBOX, Route.LOAN_EXPLORER_SHOEBOX_USER] as string[]).includes(pathname)) {
        return 'MY_SHOEBOX'
    } else if (pathname === Route.HOME) {
        return 'KANBAN'
    } else if (([Route.LOAN_EXPLORER_TRASH, Route.LOAN_EXPLORER_TRASH_LOAN_ID, Route.LOAN_EXPLORER_TRASH_SHOEBOX] as string[]).includes(pathname)) {
        return 'TRASH'
    }

    return null;
};