import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { useMessagesSearchInput } from "src/components/messages/messages-search-input/messages-search-input.state";
import { Route } from "src/constants/routing";

import { useSearchAutocomplete } from "../search-autocomplete/search-autocomplete.state";

export const useSearchMobile = () => {
    const [isOpened, setIsOpened] = useState(false);
    const router = useRouter();
    const loanSearchState = useSearchAutocomplete({});
    const messagesSearchState = useMessagesSearchInput();

    const handlePathChange = useCallback(() => {
        if (!router.isReady) {
            return;
        }

        if (open) {
            setIsOpened(false);
        }
    }, [router]);

    useEffect(() => {
        const routeChangeStart = () => {
            handlePathChange();
        };
        router.events?.on("routeChangeStart", routeChangeStart);
        return () => {
            router.events?.off("routeChangeStart", routeChangeStart);
        };
    },
        [handlePathChange, router.events]
    );

    const isSearchMessages = Route.SINGLE_LOAN_MESSAGES === router.pathname;

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isSearchMessages) {
            messagesSearchState.onFilterQueryChange(e.target.value);
        } else {
            loanSearchState.onInputChange(e);
        }
    };

    const onClearSearch = () => {
        if (isSearchMessages) {
            messagesSearchState.onFilterQueryChange('');
        } else {
            loanSearchState.onClearSearchClick();
        }
    };

    const query = isSearchMessages ? messagesSearchState.filterQuery : loanSearchState.query;

    return {
        isSearchMessages,
        isOpened,
        setIsOpened,
        onInputChange,
        onClearSearch,
        query,
        noResults: loanSearchState.noResults,
        isFetching: loanSearchState.isFetching,
        items: loanSearchState.results.items
    } as const;
};