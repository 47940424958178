import type { AppBarProps } from '@mui/material/AppBar';
import AppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import Button from 'components/dist/atoms/Button';
import Icon from 'components/dist/atoms/Icon';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import Tooltip from 'components/dist/atoms/Tooltip';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { QUERY_MESSAGE_THREAD_ID } from 'src/constants/chat';
import { LOAN_TABS } from 'src/constants/query-params';
import { Route } from 'src/constants/routing';
import { QUERY_PARAM_LOAN_SHOE_BOX_OPEN } from 'src/constants/shoebox';
import { classNames } from 'src/utils/tailwind-class-names';
import { useMediaQuery } from 'usehooks-ts';

import { FormElementsAdd } from '../form-elements/form-elements-add';
import { BellNotification } from '../notifications/bell-notification';
import { SearchAutocomplete } from '../search/search-autocomplete';
import { SearchMobile } from '../search/search-mobile/search-mobile.component';
import { AccountPopover } from './account-popover';
import { DashboardNavbarBreadcrumbs } from './dashboard-navbar-breadcrumbs';
import { DashboardNavbarLoanExplorer } from './dashboard-navbar-loan-explorer.component';

interface DashboardNavbarProps extends AppBarProps {
  onToggle: () => void;
  sidebarOpen?: boolean;
  showGoBackButton?: boolean;
  noSearch?: boolean;
  noSideBar?: boolean;
  noBreadcrumb?: boolean;
  customLeft?: `${string}px`
}

const DashboardNavbarRoot = styled(AppBar)(({
  backgroundColor: 'white',
  color: 'black',
  boxShadow: 'none'
}));


export const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onToggle, customLeft = '256px', showGoBackButton, noBreadcrumb, noSearch, noSideBar, sidebarOpen, ...other } = props;
  const router = useRouter();
  const handleGoBackClick = () => {
    // if we have a referrer, go back to it
    // otherwise go back to home page
    if (document.referrer) {
      router.back();
    } else {
      router.push({
        pathname: Route.HOME
      });
    }
  };

  const isMobile = useMediaQuery('(max-width: 640px)');
  const isLoanLink = ([
    Route.SINGLE_LOAN,
    Route.SINGLE_LOAN_MESSAGES,
    Route.SINGLE_LOAN_TASKS,
    Route.SINGLE_LOAN_TASKS_TYPE
  ] as string[]).includes(router.pathname)

  const isPackageRoute = Route.SINGLE_LOAN === router.pathname &&
    router.query.tab === LOAN_TABS.PACKAGE && !router.query[QUERY_PARAM_LOAN_SHOE_BOX_OPEN]

  const isThreadRoute = Route.SINGLE_LOAN_MESSAGES === router.pathname &&
    !!router.query[QUERY_MESSAGE_THREAD_ID];

  return <DashboardNavbarRoot
    className='border-b border-gray-200 bg-white'
    sx={(theme) => ({
      left: {
        lg: noSideBar ? '0px' : customLeft,
      },
      width: {
        lg: noSideBar ? '100%' : `calc(100% - ${customLeft})`,
      },
      transition: theme.transitions.create(['left', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      ...((!sidebarOpen && !noSideBar) && {
        width: {
          lg: 'calc(100% - 58px)',
        },
        left: {
          lg: "58px"
        },
        transition: theme.transitions.create(['left', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        })
      }),
      display: {
        xs: isThreadRoute ? 'none' : 'flex',
        sm: 'flex',
      }
    })}
    {...other}
  >
    <Stack
      row
      items='center'
      space="sm"
      className='h-[54px] min-h-[54px] left-0 p-2  print:hidden'
    >
      <Tooltip>
        <Tooltip.Trigger asChild>
          <Button
            className="text-primary hover:text-white bg-transparent mr-1 px-1 py-1 rounded-sm xl:hidden"
            onClick={onToggle}
            data-testid="sidebar-toggle-trigger"
            size="custom">
            <Icon name="Menu" width={20} height={20} strokeWidth={2} />
          </Button>
        </Tooltip.Trigger>
        <Tooltip.Content>
          <Text size="sm">
            {open ? 'Close' : 'Open'} Menu
          </Text>
        </Tooltip.Content>
      </Tooltip>
      {showGoBackButton &&
        <Button
          onClick={handleGoBackClick}
          variant='ghost'>
          <Icon name="NavArrowLeft" width={20} height={20} />
        </Button>}
      <DashboardNavbarBreadcrumbs />
      <Stack className='flex-1' space="sm" row items="center">
        <Stack
          className='flex-1 justify-end sm:justify-center'
          row>
          {(!noSearch && (!isLoanLink || isMobile)) && <>

            <div className='max-w-md hidden sm:block w-full relative'>
              <SearchAutocomplete />
            </div>
            {isMobile && <SearchMobile />}
          </>}
        </Stack>
        <DashboardNavbarLoanExplorer />
        <BellNotification />
        <Stack row items='center'>
          <div className={classNames('sm:block', {
            'hidden': isPackageRoute
          })}>
            <AccountPopover />
          </div>
          <div className={classNames('sm:hidden', {
            'hidden': !isPackageRoute
          })}>
            <FormElementsAdd
            >
              <Button
                className="px-1.5"
                size="sm">
                <Icon name="Plus" width={20} height={20} strokeWidth={1.5} />
              </Button>
            </FormElementsAdd>
          </div>
        </Stack>
      </Stack>
    </Stack>
  </DashboardNavbarRoot>
};
