import Button from 'components/dist/atoms/Button';
import Icon from 'components/dist/atoms/Icon';
import { useRouter } from "next/router";
import { ParsedUrlQuery } from 'querystring';
import { QUERY_PARAM_LOAN_ID } from 'src/constants/query-params';
import { Route } from "src/constants/routing";

import { MysherpasTooltip } from "../common/mysherps-tooltip";
import { NextLinkComposed } from "../common/next-link-composed";

export const DashboardNavbarLoanExplorer = () => {
    const router = useRouter();
    const isLoanExplorerActive = router.pathname.startsWith('/explorer');
    let query: ParsedUrlQuery = {};
    let pathname: string = Route.LOAN_EXPLORER_LOANS;

    if (Route.LOAN_EXPLORER_LOANS === router.pathname) {
        pathname = Route.HOME;
    } else if (([
        Route.SINGLE_LOAN,
        Route.SINGLE_LOAN_MESSAGES,
        Route.SINGLE_LOAN_TASKS,
    ] as string[]).includes(router.pathname)) {
        pathname = Route.LOAN_EXPLORER_LOANS_LOAN_ID;
        query = {
            [QUERY_PARAM_LOAN_ID]: router.query[QUERY_PARAM_LOAN_ID]
        };
    } else if (([
        Route.LOAN_EXPLORER_LOANS_LOAN_ID,
        Route.LOAN_EXPLORER_LOANS_LOAN_ID_PACKAGE,
        Route.LOAN_EXPLORER_LOANS_LOAN_ID_SHOEBOX,
        Route.LOAN_EXPLORER_LOANS_LOAN_ID_SHOEBOX_USER_ID
    ] as string[]).includes(router.pathname)) {
        pathname = Route.SINGLE_LOAN;
        query = {
            [QUERY_PARAM_LOAN_ID]: router.query[QUERY_PARAM_LOAN_ID]
        };
    }
    return <MysherpasTooltip title={`${isLoanExplorerActive ? "Close" : "Open"} Loan Explorer`}>
        <Button
            size='sm'
            className='w-8 aspect-square'
            variant='outline'
            asChild>
            <NextLinkComposed
                className={isLoanExplorerActive ? "bg-blue-100 text-white border-blue-100" : ""}
                to={{
                    pathname: pathname,
                    query
                }}
            >
                <Icon name="ArchiveDrawer" width={20} height={20} />
            </NextLinkComposed>
        </Button>
    </MysherpasTooltip>
};