import Button from 'components/dist/atoms/Button';
import Dialog from 'components/dist/atoms/Dialog';
import Icon from 'components/dist/atoms/Icon';
import Input from 'components/dist/atoms/Input';
import { BorrowerPortalMessagesSearchResults } from 'src/components/borrower-portal/borrower-portal-messages/borrower-portal-messages-search-results';

import { SearchAutocompleteResults } from '../search-autocomplete/search-autocomplete-results.component';
import { useSearchMobile } from './search-mobile.state';

export const SearchMobile = () => {
    const state = useSearchMobile();

    return <Dialog
        open={state.isOpened}
        onOpenChange={state.setIsOpened}
    >
        <Dialog.Trigger asChild>
            <Button
                className='sm:hidden'
                size='xs'
                variant='ghost'
                circle>
                <Icon name="Search" width={20} height={20} strokeWidth={1.5} />
            </Button>
        </Dialog.Trigger>
        <Dialog.Content className='w-full h-full overflow-hidden bg-black-10'>
            <div className='flex items-center justify-between w-full p-3 bg-white'>
                <Dialog.Close asChild>
                    <Button size='xs' variant='ghost' circle>
                        <Icon name="NavArrowLeft" width={20} height={20} strokeWidth={1.5} />
                    </Button>
                </Dialog.Close>
                <Input
                    wrapClassName='border-white focus-within:bg-white focus-within:border-blue-100 w-full rounded-full bg-black-20 pr-0'
                    onChange={state.onInputChange}
                    value={state.query}
                    type='text'
                    placeholder='Search' >
                    <Input.Prefix>
                        <Icon name="Search" width={20} height={20} strokeWidth={1.5} />
                    </Input.Prefix>
                    {state.query && <Input.Suffix>
                        <Button
                            onClick={state.onClearSearch}
                            size='custom'
                            variant='ghost'
                            className='px-3 text-black-primary'
                            circle>
                            <Icon name="Cancel" width={20} height={20} strokeWidth={1.5} />
                        </Button>
                    </Input.Suffix>}
                </Input>
            </div>
            {!state.isSearchMessages && <div className='flex-1 overflow-x-auto scrollbar-stable px-3'>
                <SearchAutocompleteResults
                    query={state.query}
                    noResults={state.noResults}
                    isFetching={state.isFetching}
                    results={state.items}
                />
            </div>}
            {state.isSearchMessages && <div className='px-2 flex flex-col gap-2 overflow-hidden'>
                <BorrowerPortalMessagesSearchResults />
            </div>}
        </Dialog.Content>
    </Dialog>
};