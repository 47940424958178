import { IconProps } from 'components/dist/atoms/Icon';
import { FilesDropContainerForwardRefProps } from 'components/dist/molecules/FilesDropContainer';
import { useRouter } from 'next/router';
import React from 'react';
import { DocumentType } from 'src/backend';
import { DuplicateTabs } from 'src/components/duplicate-tabs/duplicate-tabs.component';
import { SimpleViewFolderNames } from 'src/constants/form-element';
import { LOAN_TABS, QUERY_PARAM_FORM_ELEMENT_ID } from 'src/constants/query-params';
import { Route } from "src/constants/routing";
import { QUERY_EXPAND_FILE_PREVIEW } from 'src/constants/ui';
import { useFormElementContext } from 'src/contexts/form-element-context';
import { useUploadFormElementContext } from 'src/contexts/upload-form-element-context/upload-form-element-context';
import { useGetElements } from 'src/hooks/use-get-elements';


interface DropdownItem {
    type: 'SEPARATOR' | 'ITEM';
    action: () => void | null;
    iconName: IconProps['name'];
    name: string;
    subContent?: React.ReactNode;
}

const separatorItem: DropdownItem = {
    type: 'SEPARATOR',
    action: null,
    iconName: null,
    name: '',
}

export const useFormElementsAddState = () => {
    const uploadFilesRef = React.useRef<FilesDropContainerForwardRefProps>(null);
    const uploadFoldersRef = React.useRef<FilesDropContainerForwardRefProps>(null);
    const uploadFormElementContext = useUploadFormElementContext();
    const { onSetEditFileRequestIds } = useFormElementContext();

    const router = useRouter();
    const loanId = router.query.loanId as string;
    const elementsState = useGetElements({ loanId });
    const formElements = elementsState.list;
    const formElementsSet = formElements.reduce((acc, next) => ({ ...acc, [next.id]: next }), {});

    const currentFormElementId = router.query.formElementId ? String(router.query.formElementId) : elementsState.rootElementId;

    const queryFormElement = formElementsSet[currentFormElementId];
    const activeFolderId = queryFormElement?.storageType === 'FOLDER' ? queryFormElement.id : (queryFormElement?.parentId ?? elementsState.rootElementId);
    const formElement = formElementsSet[activeFolderId];

    const onDropFiles = (droppedFiles: File[]) => {
        uploadFormElementContext.onDropFiles({
            droppedFiles,
            targetElement: formElement,
            shareWithUserIds: []
        });
    };


    const query = {
        loanId: loanId,
        tab: LOAN_TABS.PACKAGE,
        [QUERY_PARAM_FORM_ELEMENT_ID]: router.query[QUERY_PARAM_FORM_ELEMENT_ID],
        [QUERY_EXPAND_FILE_PREVIEW]: router.query[QUERY_EXPAND_FILE_PREVIEW],
    }

    const handleAddEntityClick = () => {
        router.push({
            pathname: Route.CREATE_LOAN_ENTITY,
            query
        })
    }

    const handleAddAssetClick = () => {
        router.push({
            pathname: Route.CREATE_LOAN_ASSET,
            query
        })
    }

    const handleCreateQuestionClick = () => {
        onSetEditFileRequestIds(['new'])
    }

    const handleCreateSectionClick = () => {
        uploadFormElementContext.onConsolidatedViewChange({
            dropType: "FILE",
            items: [],
            formElement: formElement,
            storageType: 'FOLDER'
        });
    }

    const isLoanPackageFolder = !formElement?.parentId;
    const isCollateralDocsFolder = formElement?.title === SimpleViewFolderNames.Collateral;

    const dropdownItems: DropdownItem[] = [{
        type: 'ITEM',
        action: handleCreateSectionClick,
        iconName: "FolderEmpty" as const,
        name: 'Folder',
    }, {
        type: 'ITEM',
        action: handleCreateQuestionClick,
        iconName: "DocumentPlus" as const,
        name: 'File Request',
    },
    {
        ...separatorItem,
        name: 'Separator'
    },
    {
        type: 'ITEM',
        action: () => uploadFoldersRef.current?.openFilePicker(),
        iconName: "FolderUpload" as const,
        name: 'Folder Upload',
    }, {
        type: 'ITEM',
        action: () => uploadFilesRef.current?.openFilePicker(),
        iconName: "DocumentUpload" as const,
        name: 'File Upload',
    },
    {
        ...separatorItem,
        name: 'Separator3'
    },
    {
        name: 'Duplicate Tab',
        iconName: 'DuplicateOutline' as const,
        action: () => { },
        type: 'ITEM' as const,
        subContent: <DuplicateTabs />
    },]

    if (isLoanPackageFolder || isCollateralDocsFolder) {
        dropdownItems.push({
            ...separatorItem,
            name: 'Separator2'
        });
    }

    if (isLoanPackageFolder) {
        dropdownItems.push({
            type: 'ITEM',
            action: handleAddEntityClick,
            iconName: "UserBag" as const,
            name: 'Entity',
        });
    }
    if (isLoanPackageFolder || isCollateralDocsFolder) {
        dropdownItems.push({
            type: 'ITEM',
            action: handleAddAssetClick,
            iconName: "Commodity" as const,
            name: 'Collateral',
        });
    }

    const handleAddDocumentClick = async (type: DocumentType) => {
        uploadFormElementContext.onCreateSharepointDocument({
            loanId: loanId,
            documentType: type,
            newDocumentTitle: type,
            parentId: formElement.id,
            storageType: 'FILE'
        })
    };


    dropdownItems.push(...[
        {
            ...separatorItem,
            name: 'Separator4'
        },
        {
            type: 'ITEM' as const,
            action: () => handleAddDocumentClick("WORD_DOCX"),
            iconName: "MsWord" as const,
            name: 'Word Document',
        },
        {
            type: 'ITEM' as const,
            action: () => handleAddDocumentClick("EXCEL"),
            iconName: "MsExcel" as const,
            name: 'Excel Workbook',
        },
        {
            type: 'ITEM' as const,
            action: () => handleAddDocumentClick("POWERPOINT"),
            iconName: "MsPowerpoint" as const,
            name: 'PowerPoint Document',
        }
    ])

    return {
        dropdownItems,
        onDropFiles,
        uploadFilesRef,
        uploadFoldersRef,

    } as const;
}