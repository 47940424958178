
import Icon from 'components/dist/atoms/Icon';
import Separator from 'components/dist/atoms/Separator';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import FilesDropContainer from 'components/dist/molecules/FilesDropContainer';
import ScreenSizeDropdownMenu from 'components/dist/molecules/ScreenSizeDropdownMenu';
import React from 'react';

import { useFormElementsAddState } from './form-elements-add.hook';


export const FormElementsAdd = (props: React.PropsWithChildren<object>) => {
    const state = useFormElementsAddState();
    return <>
        <FilesDropContainer
            ref={state.uploadFilesRef}
            onFilesDrop={state.onDropFiles}
            multiple
        />
        <FilesDropContainer
            ref={state.uploadFoldersRef}
            uploadFolders
            onFilesDrop={state.onDropFiles}
            multiple
        />
        <ScreenSizeDropdownMenu
            modal={false}>
            <ScreenSizeDropdownMenu.Trigger asChild>
                {props.children}
            </ScreenSizeDropdownMenu.Trigger>
            <ScreenSizeDropdownMenu.Content
                align='start'
                side='bottom'
                className="w-full p-4 sm:w-72  absolute gap-3">
                {state.dropdownItems.map((item) => {
                    if (item.type === 'SEPARATOR') {
                        return <Separator key={item.name} />
                    } else if (item.subContent) {
                        return <ScreenSizeDropdownMenu.Sub
                            key={item.name}>
                            <ScreenSizeDropdownMenu.SubTrigger asChild>
                                <ScreenSizeDropdownMenu.Item
                                    asChild
                                    key={item.name}>
                                    <Stack row space='sm' className='pointer-events-none' items='center'>
                                        <Icon name={item.iconName} width={20} height={20} strokeWidth={1.5} />
                                        <Text as="div" truncate size='sm'>
                                            {item.name}
                                        </Text>
                                    </Stack>
                                </ScreenSizeDropdownMenu.Item>
                            </ScreenSizeDropdownMenu.SubTrigger>
                            <ScreenSizeDropdownMenu.Portal>
                                <ScreenSizeDropdownMenu.SubContent
                                    sideOffset={0}
                                    className='pt-2 sm:p-0 w-full sm:w-72 max-w-full '>
                                    {item.subContent}
                                </ScreenSizeDropdownMenu.SubContent>
                            </ScreenSizeDropdownMenu.Portal>
                        </ScreenSizeDropdownMenu.Sub>
                    }
                    return (<ScreenSizeDropdownMenu.Item
                        key={item.name}
                        className='p-0'
                        onSelect={item.action}
                    >
                        <Stack row space="sm" className="h-10 p-2 items-center w-full cursor-pointer" >
                            <Icon
                                strokeWidth={1.5}
                                name={item.iconName}
                                width={20}
                            />
                            <Text size="sm">{item.name}</Text>
                        </Stack>
                    </ScreenSizeDropdownMenu.Item>)
                })}
            </ScreenSizeDropdownMenu.Content>
        </ScreenSizeDropdownMenu>
    </>;
}
