import Breadcrumb from 'components/dist/atoms/Breadcrumb';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import { QUERY_PARAM_PACKAGE_TASK_FILTER } from 'src/constants/form-element';
import { LOAN_TABS } from 'src/constants/query-params';
import { Route } from "src/constants/routing";
import { QUERY_PARAM_LOAN_SHOE_BOX_OPEN } from 'src/constants/shoebox';
import { TaskGroupIdLoanSidebarMap, TaskGroupLabel } from 'src/constants/task';
import { useUser } from 'src/hooks/use-user';
import { useGetLoanByIdQuery } from 'src/services/loanApi';
import { useMediaQuery } from 'usehooks-ts';

import { NextLinkComposed } from '../common/next-link-composed';


export const DashboardNavbarBreadcrumbs = () => {
    const router = useRouter();
    const { company } = useUser();
    const isMobile = useMediaQuery('(max-width: 640px)');
    const loanData = useGetLoanByIdQuery(String(router.query.loanId), {
        skip: !router.query.loanId
    });

    const isLoanExplorer = router.pathname.includes('/explorer');
    const BreadcrumbItems: {
        label: string;
        pathname: string;
        query: Record<string, string>;
    }[] = [];

    if (([
        Route.HOME,
        Route.LOANS_PHASE_CATEGORY] as string[]).includes(router.pathname)) {
        BreadcrumbItems.push({
            label: 'Loans',
            pathname: Route.HOME,
            query: {}
        });
    }
    if (([
        Route.SINGLE_LOAN,
        Route.SINGLE_LOAN_TASKS,
        Route.SINGLE_LOAN_MESSAGES,] as string[]).includes(router.pathname) &&
        !isMobile) {
        BreadcrumbItems.push({
            label: 'Loans',
            pathname: Route.HOME,
            query: {}
        });
    }
    // if we have a phase query param, add the phase breadcrumb
    if (router.query.phase) {
        // if phase is LEAD, add the lead breadcrumb with Leads label
        if (router.query.phase === 'LEAD' && !isMobile) {
            BreadcrumbItems.push({
                label: 'Leads',
                pathname: Route.HOME,
                query: {
                    phase: 'LEAD'
                }
            });
            // for other phases , add the phase breadcrumb with the phase label
        } else if (!isMobile) {
            const phase = String(router.query.phase).toLowerCase();
            BreadcrumbItems.push({
                label: phase,
                pathname: Route.LOANS_PHASE_CATEGORY,
                query: {
                    phase: phase
                }
            });
        }
    }
    // if we don't have a phase and we don't have a loan, 
    // and user is lender
    // and we are on homepage or loan page
    // and we are not on loan explorer pages
    // return Origination as the default
    if (!router.query.phase &&
        !router.query.loanId &&
        !isMobile &&
        (router.pathname === Route.HOME || router.pathname.includes('/loans')) &&
        !isLoanExplorer) {
        BreadcrumbItems.push({
            label: 'Origination',
            pathname: Route.HOME,
            query: {
                phase: 'ORIGINATION'
            }
        });
    }
    // else if loan explorer
    else if (isLoanExplorer && company) {
        BreadcrumbItems.push({
            label: company?.name,
            pathname: Route.HOME,
            query: {}
        });
    }
    // if we have loan data, add the loan breadcrumbs
    if (loanData.currentData) {
        let openTab = 'Overview'
        if (router.query.tab === LOAN_TABS.PACKAGE) {
            openTab = 'Package';
        }
        if (router.pathname === Route.SINGLE_LOAN_MESSAGES) {
            openTab = 'Messages';
        } else if (router.query[QUERY_PARAM_LOAN_SHOE_BOX_OPEN] === '1') {
            openTab = 'Shoebox';
        } else if (Route.SINGLE_LOAN_TASKS === router.pathname) {
            openTab = 'Tasks';
        } else if (Route.SINGLE_LOAN_TASKS_TYPE === router.pathname) {
            const task = router.query.type as string
            openTab = TaskGroupLabel[TaskGroupIdLoanSidebarMap[task]];
        } else if (router.query[QUERY_PARAM_PACKAGE_TASK_FILTER]) {
            const task = router.query[QUERY_PARAM_PACKAGE_TASK_FILTER] as string
            openTab = TaskGroupLabel[TaskGroupIdLoanSidebarMap[task]];
        }
        if (!isMobile && !isLoanExplorer) {
            BreadcrumbItems.push({
                label: loanData.currentData.loanPhase.category.toLowerCase(),
                pathname: Route.LOANS_PHASE_CATEGORY,
                query: {
                    phase: loanData.currentData.loanPhase.category
                }
            });
        }

        BreadcrumbItems.push({
            label: isMobile
                ? loanData.currentData.projectName
                : `${loanData.currentData.projectName} (${loanData.currentData.shortCode})`,
            pathname: Route.SINGLE_LOAN,
            query: {
                loanId: loanData.currentData.id
            }
        });
        if (!isMobile) {
            BreadcrumbItems.push({
                label: openTab,
                pathname: Route.SINGLE_LOAN,
                query: {
                    loanId: loanData.currentData.id
                }
            });
        }
    }

    if (BreadcrumbItems.length === 0) {
        return null;
    }

    return <div className='overflow-hidden' >
        <Breadcrumb className='overflow-hidden'>
            <Breadcrumb.List>
                {BreadcrumbItems.map((item) => {
                    const isLast = BreadcrumbItems[BreadcrumbItems.length - 1] === item;
                    return <Fragment key={`${item.pathname}-${item.label}`}>
                        <Breadcrumb.Item className='capitalize'>
                            {!isLast && <Breadcrumb.Link asChild>
                                <NextLinkComposed to={{
                                    pathname: item.pathname,
                                    query: item.query
                                }}>
                                    <span className='truncate'>{item.label}</span>
                                </NextLinkComposed>
                            </Breadcrumb.Link>}
                            {isLast && <span className='truncate'>{item.label}</span>}
                        </Breadcrumb.Item>
                        {!isLast && (<Breadcrumb.Separator />)}
                    </Fragment>
                }
                )}
            </Breadcrumb.List>
        </Breadcrumb>
    </div>
}